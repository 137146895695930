<template>
    <div class="ruler-alt">
        <div style="display:flex;justify-content: space-between;">
            <span class="d-inline-block border-3left-f20 text-indent-20 color-0 fz-24">操作记录</span>
            <i-input v-model='search_text'  @on-enter="CheckLog" icon="ios-search" placeholder="请输入关键字搜索规则" class="iv_input" style="height:27px;width: 200px;border-radius: 4px;padding: 1px;" size="small"></i-input>
           <div >
           </div>
        </div>
     
         <div class="table_box">
                <liu-table :columns="columns" :dataSouces="dataSouces"/>
            </div>
            <div style="text-align: center;">
                <v-pagination
                    :total="total"
                    :pageSize="pageSize"
                    :pageIndex="pageNum"
                    @page-change="nextPage"
                    @page-size-change="pageSizeChange"
                    size="middle"></v-pagination>
        </div>
  </div>
</template>
<script>
import {CheckLog} from '@/api.js'
export default {
    components: {
        liuTable: require('@/components/liu_table/table.vue').default,
       
    },
    data () {
        return {
            type: 'system',
            search_text: '',
            total: 100,
            pageSize: 10,
            pageNum: 1,
            columns: [
                {
                    title: '用户',
                    key: 'user_name',
                },
                {
                    title: '版本号',
                    key: 'rule_version',
                },
                {
                    title: '描述',
                    key: 'operation',
                },
                {
                   title: '详细描述',
                   key: 'operation_desc',  
                }
            ],
            dataSouces: [
            ],
            allDataSouce:[]
           
        }
        
    },
  
    methods: {
        nextPage (pageNum) {
            this.pageNum = pageNum 
            this.dataSouces = this.setPaging(this.allDataSouce, pageNum, this.pageSize)
          
        },
        pageSizeChange (pageSize) {
           this.pageNum = 1
           this.pageSize = pageSize
           this.dataSouces = this.setPaging(this.allDataSouce, 1, this.pageSize)
        },
         setPaging (arr=[], pageNum = 0, num ) {
                var r_arr  = [], cout = 0 
                pageNum--
                var startIndex = pageNum * num
                
                for (let index = startIndex; index < arr.length; index++) {
                  
                    r_arr.push(arr[index]) 
                    cout++
                    if(num == cout ) break
                }
                return r_arr
        },
        CheckLog() {
            this.pageNum = 1
            CheckLog({
                msg: this.search_text
            }).then(res => {
                const {data} = res
                if(data.status == 200) {
                  this.allDataSouce = data.msg
                   this.total = data.msg.length
                  this.dataSouces=this.setPaging(data.msg, this.pageNum, this.pageSize)
                }else{
                    this.$Message.warning(data.msg)
                }
            })
        }
    },
    
    created () {
        this.CheckLog()
    },
   
   
  
   
}
</script>

<style lang="less" scoped>
   
    .ruler-alt{
        background: #ffffff;
        padding: 1.5rem 15px;
        position: relative;
        color: #444;

}
</style>
