<template>
  <div class="table_box" :style="style">
    <table
      class="table df color-5d per tb-hover liu-table"
      :class="[border && 'liu-table-border', width && 'table-layout']"
    >
      <thead class="per text-center">
        <tr>
          <th
            v-for="item in columns"
            :key="item.key"
            :width="item.width && item.width"
            style="position: relative; border-bottom: none; border-top: none;"
          >
            <titleRender :titleRender="item.titleRender || item.title" />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in sum" :key="index">
          <!-- {{sum}} -->
          <td
            v-for="columnItem in columns"
            :key="columnItem.key"
            :style="columnItem.style ? columnItem.style : ''"
            style="border-bottom: none; border-top: none;"
          >
            <!-- {{item[columnItem.key]}} -->
            <Extend
              :row="item"
              :text="item[columnItem.key]"
              :render="columnItem.render ? columnItem.render : item[columnItem.key]"
              :index="index"
            />
          </td>
        </tr>
        <tr v-if="!dataSouces.length">
          <td :colspan="columns.length">{{ dec ? dec : "当前无对标企业，请手动添加" }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  components: {
    Extend: require("./render").default,
    titleRender: require("./titleRender").default,
  },
  name: "Table",
  props: {
    columns: Array,
    dataSouces: Array,
    border: Boolean,
    width: Boolean,
    dec: String,
    maxHeight: Number,
  },
  data() {
    return {
      style: {},
    };
  },
  created() {
    if (this.maxHeight) {
      this.style = {
        maxHeight: this.maxHeight + "px",
        overflowY: "auto",
        marginTop: "10px",
      };
    }
  },
  methods: {},
  computed: {
    sum() {
      var dataSoucesArr = [];
      dataSoucesArr = this.dataSouces.filter((v) => {
        if (v.whitewash_level != "无法计算") return v;
      });
      return dataSoucesArr;
    },
  },
};
</script>
<style lang="less" scoped>
.liu-table {
  text-align: center;
  // border-left: 1px solid #dee2e6;
  // border-bottom: 1px solid #dee2e6;
  // border-right: 1px solid #dee2e6;

  tbody {
    text-align: center;
    background-color: #FAFAFA !important;
    tr {
      td {
        border-top: none !important;
      }
    }
  }
}
.liu-table-border {
  border: none;
  thead {
    tr {
      th {
        // border-left: 1px solid #dee2e6;
        border-bottom: none !important;
      }
    }
  }
  // tbody {
  //   tr {
  //     // border-left: 1px solid #dee2e6 !important;
  //   }
  // }
}
.table-layout {
  table-layout: fixed;
}
</style>
