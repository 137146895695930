export default {
    name: 'titleRender',
    functional: true,
    render: (h, vm) => {
      
      
       if(typeof(vm.props.titleRender)=="string" || typeof(vm.props.titleRender)=="number") {
            return h(
             "div", vm.props.titleRender
             )
       }else {
         
          
            
           return vm.props.titleRender && vm.props.titleRender(h, vm, )
       }
       
       
    }
};