export default {
    name: 'TableExpand',
    functional: true,
    render: (h, vm) => {
      
      
       if(typeof(vm.props.render)=="string" || typeof(vm.props.render)=="number") {
            return h(
            //  "div", reasonReportMapping[vm.props.render] || ( isNaN(parseInt(vm.props.render)) ? vm.props.render : vm.props.render+'年' )
             "div", vm.props.render
             )
       }else {
         
          
            
           return vm.props.render && vm.props.render(h, vm.props.text, vm.props.row,  vm.props.index)
       }
       
       
    }
};